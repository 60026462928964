import {
  Clock,
  File,
  MapPin,
  User,
  Users,
} from "react-feather";
export const superAdminsideMenu = [
  {
    label: "City",
    to: "/city-list",
    icon: <MapPin />
  },
  {
    label: "Ward",
    to: "/ward-list",
    icon: <Clock />,
  },
  {
    label: "Add Supervisor",
    to: "/wardmember-list",
    icon: <User />,
  },
  {
    label: "Admin Management",
    to: "/admin-list",
    icon: <User />,
  },
  {
    label: "Staff Management",
    to: "/staff-list",
    icon: <Users />
  },
  {
    label: "Create Complain",
    to: "/complain-list",
    icon: <File />
  }
]

export const AdminMenu = [
  {
    label: "Ward",
    to: "/ward-list",
    icon: <Clock />,
  },
  {
    label: "Add Supervisor",
    to: "/wardmember-list",
    icon: <User />,
  },
  {
    label: "Staff Management",
    to: "/staff-list",
    icon: <Users />,
  },
  {
    label: "Complain Management",
    to: "/complaints",
    icon: <File />
  }
]

export const StaffSideMenu = [
  {
    label: "Create Complain",
    to: "/complain-list",
    icon: <File />
  },
]
export const sideMenu = [
  {
    label: "City",
    to: "/city-list",
    icon: <MapPin />
  },
  {
    label: "Ward",
    to: "/ward-list",
    icon: <Clock />,
  },
  {
    label: "Add Ward Member",
    to: "/wardmember-list",
    icon: <User />,
  },
  {
    label: "Admin Management",
    to: "/admin-list",
    icon: <User />,
  },
  {
    label: "Staff Management",
    to: "/staff-list",
    icon: <Users />
  },
  {
    label: "Create Complain",
    to: "/complain-list",
    icon: <File />
  }

];
