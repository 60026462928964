import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { setComplainTicket } from "../../redux/complainticketSlice";
import { useComplainTicketByIdQuery } from "../../service";
import { setComplainEdit } from "../../redux/complainSlice";
function TicketUpdate() {
  const params = useParams();
  const dispatch = useDispatch();
  const resComplainTicketById = useComplainTicketByIdQuery(params.id);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();
  const editData = useSelector((state) => state.complainState.complainEdit);
  console.log("editdata", editData);
  console.log("resComplainTicketById", resComplainTicketById?.data?.data);
  useEffect(() => {
    if (resComplainTicketById?.isSuccess) {
      console.log("resComplainTicketById", resComplainTicketById?.data?.data);
      reset({
        ...resComplainTicketById?.data?.data,
        complainId: resComplainTicketById?.data?.data?.complainId,
        // complainstatus: {
        //   label: resComplainTicketById?.data?.data?.complainstatus,
        //   value: resComplainTicketById?.data?.data?.complainstatus,
        // },
        complainstatus: resComplainTicketById?.data?.data?.complainstatus,
        image: resComplainTicketById?.data?.data?.image?.filepath,
      });
      dispatch(setComplainEdit(resComplainTicketById?.data?.data));
    }
  }, [resComplainTicketById]);

  const onSubmit = async (state) => {};
  return (
    <div>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="6" sm="12" className="mb-1">
              <Label className="form-label" for="complainId">
                Ticket Number
              </Label>
              <Controller
                id="complainId"
                name="complainId"
                control={control}
                rules={{ required: "Phone Number is required" }}
                render={({ field }) => (
                  <Input
                    type="string"
                    placeholder="Ticket Number"
                    {...field}
                    name="complainId"
                  />
                )}
              />
              {errors?.complainId && (
                <FormFeedback>{errors?.complainId?.message}</FormFeedback>
              )}
            </Col>
            <Col md="6" sm="12" className="mb-1">
              <Label for="complainstatus">Current Status</Label>
              <Controller
                id="complainstatus"
                name="complainstatus"
                control={control}
                rules={{ required: "Ticket Status is required" }}
                render={({ field }) => (
                  <Input
                    type="string"
                    placeholder="Current status"
                    {...field}
                    name="complainstatus"
                  />
                )}
              />
              {errors.complainstatus && (
                <FormFeedback>{errors?.complainstatus?.message}</FormFeedback>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={editData?.image?.filepath ? "11" : "12"} className="mb-1">
              <Label for="image">Image</Label>
              {/* Render image if editData contains image */}
              {editData?.image?.filepath && (
                <div>
                  <img
                    src={editData.image.filepath}
                    height={50}
                    width={50}
                    style={{ marginTop: "1rem", display: "block" }}
                    alt=""
                  />
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
}

export default TicketUpdate;
