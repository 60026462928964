import { createSlice } from "@reduxjs/toolkit";

const initState = {
    cityList:[],
    cityView:null,
    cityEdit:null,
    cityAll:[],
    cityDropdown: [],
};

export const citySlice = createSlice({
      name : "city",
      initialState: initState,
      reducers: {
        getCity: (state,{payload}) => {
            state.cityList = payload;
        },
        setCityView: (state,{payload}) => {
            state.cityView = payload;
        },
        setCityEdit: (state,{payload}) => {
            state.cityEdit = payload;
        },
        setCity: (state,{payload}) => {
            state.cityAll = payload;
        },
        setCityDropdown:(state,{payload}) => {
            state.cityDropdown = payload;
        },
      }
})

export const {
    getCity,
    setCityView,
    setCityEdit,
    setCity,
    setCityDropdown
} = citySlice.actions;

export default citySlice.reducer;