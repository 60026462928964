import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  authApi,
  fileApi,
  linksApi,
  notificationApi,

  /* MUzafarnagar API */
  cityApi,
  staffApi,
  wardApi,
  wardMemberApi,
  complainApi,
  complainTicketApi,
} from "../service";

/**** MuzafarNagar imports */

import citySlice from "./citySlice";
import staffSlice from "./staffSlice";
import wardSlice from "./wardSlice";
import wardMemberSlice from "./wardMemberSlice";
import complainSlice from "./complainSlice";
import authSlice from "./authSlice";
import complainticketSlice from "./complainticketSlice";
const appReducer = combineReducers({
  /* Muzafarnagar slice */
  authState: authSlice,
  cityState: citySlice,
  wardState: wardSlice,
  wardMemberState: wardMemberSlice,
  complainState: complainSlice,
  staffState: staffSlice,
  complainticketState: complainticketSlice,
  [authApi.reducerPath]: authApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [linksApi.reducerPath]: linksApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,

  /*** MuzafarNagar appReducer ****/

  [cityApi.reducerPath]: cityApi.reducer,
  [staffApi.reducerPath]: staffApi.reducer,
  [wardApi.reducerPath]: wardApi.reducer,
  [wardMemberApi.reducerPath]: wardMemberApi.reducer,
  [complainApi.reducerPath]: complainApi.reducer,
  [complainTicketApi.reducerPath]: complainTicketApi.reducer,
});

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaltMiddleware) =>
    getDefaltMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      fileApi.middleware,
      linksApi.middleware,
      notificationApi.middleware,

      //MMCL MIDDLEWARE
      cityApi.middleware,
      staffApi.middleware,
      wardApi.middleware,
      wardMemberApi.middleware,
      complainApi.middleware,
      complainTicketApi.middleware,
    ]),
});
