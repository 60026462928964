import { createSlice } from "@reduxjs/toolkit";

const initState = {
    wardMemberList:[],
    wardMemberView:null,
    wardMemberEdit:null,
    wardMemberAll:[],
    // wardByCityId:[],
    wardMemberDropdown: [],
};

export const wardMemberSlice = createSlice({
      name : "wardMember",
      initialState: initState,
      reducers: {
        getWardMember: (state,{payload}) => {
            state.wardMemberList = payload;
        },
        setWardMemberView: (state,{payload}) => {
            state.wardMemberView = payload;
        },
        setWardMemberEdit: (state,{payload}) => {
            state.wardMemberEdit = payload;
        },
        setWardMember: (state,{payload}) => {
            state.wardMemberAll = payload;
        },
        // setWardByCityId:(state,{payload})=>{
        //     state.wardByCityId = payload;
        // },
        setWardMemberDropdown:(state,{payload}) => {
            state.wardMemberDropdown = payload;
        },
      }
})

export const {
    getWardMember,
    setWardMemberView,
    setWardMemberEdit,
    setWardMember,
    setWardByCityId,
    setWardMemberDropdown
} = wardMemberSlice.actions;

export default wardMemberSlice.reducer;