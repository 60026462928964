import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { setComplainTicket } from "../../redux/complainticketSlice";
import { useComplainTicketByIdQuery,useCreatComplainMutation, useFileUploadMutation,useEditComplainMutation } from "../../service";
import { setComplainEdit } from "../../redux/complainSlice";
function TicketUpdate() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(params.id);
  const resComplainTicketById = useComplainTicketByIdQuery(params.id);
  const [reqComplain, resComplain] = useEditComplainMutation();
  const [reqFile] = useFileUploadMutation();
  const editData = useSelector((state) => state.complainState.complainEdit);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();
   console.log("edit response",resComplain);
  useEffect(() => {
    if (resComplainTicketById?.isSuccess) {
      console.log("resComplainTicketById", resComplainTicketById?.data?.data);
      reset({
        // ...resComplainTicketById?.data?.data,
        _id: resComplainTicketById?.data?.data?._id,
        complainId: resComplainTicketById?.data?.data?.complainId,
        complainstatus: {
          label: resComplainTicketById?.data?.data?.complainstatus,
          value: resComplainTicketById?.data?.data?.complainstatus,
        },
         image: {

          _id: resComplainTicketById?.data?.data?.image?._id,
          originalname: resComplainTicketById?.data?.data?.image?.originalname,
          filepath: resComplainTicketById?.data?.data?.image?.filepath,
        },
      });
      dispatch(setComplainEdit(resComplainTicketById?.data?.data));
    }
  }, [resComplainTicketById]);

  useEffect(() => {
    if (resComplain?.isSuccess) {
      toast.success(resComplain?.data?.message, {
        position: "center",
        duration: 3000,
      });
      // navigate("/complain-list");
    }
  }, [resComplain?.isSuccess]);

  useEffect(() => {
    if (resComplain?.isError) {
      toast.error(
        resComplain?.error?.data?.message
          ? resComplain?.error?.data?.message
          : "Something Went Wrong",
        {
          position: "top-center",
          duration: 3000,
        }
      );
    }
  }, [resComplain?.isError]);

  const handleFile = (fileVal, name) => {
    const formData = new FormData();
    formData.append("file", fileVal.target.files[0]);
    const reqData = {
      file: formData,
      type: 99,
    };
    reqFile(reqData)
      .then((res) => {
        if (res?.data?.data) {
          const parts = res?.data?.data?.originalname.split(".");
          const fileNameWithoutExtension = parts.slice(0, -1).join(".");
          setValue("alt", fileNameWithoutExtension);
          setValue(name, res?.data?.data);
          setError(name, "");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onSubmit = async (state) => {
    console.log("submit data",state);
    // const payload = {
    //   ...state,
    //   image: state?.image?._id,
    //   cityname: state?.cityname?.value,
    //   wmember: state?.wmember?.value,
    //   wname: state?.wname?.value,
    //   complainstatus: state?.complainstatus?.value,
    // };
    reqComplain(state);
  };
  return (
    <div>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="6" sm="12" className="mb-1">
              <Label className="form-label" for="complainId">
                Ticket Number
              </Label>
              <Controller
                id="complainId"
                name="complainId"
                control={control}
                rules={{ required: "Phone Number is required" }}
                render={({ field }) => (
                  <Input
                    type="string"
                    placeholder="Ticket Number"
                    {...field}
                    name="complainId"
                  />
                )}
              />
              {errors?.complainId && (
                <FormFeedback>{errors?.complainId?.message}</FormFeedback>
              )}
            </Col>
            <Col md="6" sm="12" className="mb-1">
              <Label for="complainstatus">Select Status</Label>
              <Controller
                id="complainstatus"
                name="complainstatus"
                control={control}
                rules={{ required: "Ticket Status is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isClearable
                    options={[
                      { label: "Pending", value: "Pending" },
                      { label: "Work In Progress", value: "Work In Progress" },
                      { label: "Closed", value: "Closed" },
                    ]}
                    className="react-select"
                    classNamePrefix="select"
                    onChange={onChange}
                    value={value ? value : null}
                  />
                )}
              />
              {errors.complainstatus && (
                <FormFeedback>{errors?.complainstatus?.message}</FormFeedback>
              )}
            </Col>
          </Row>
          <Row>
          <Col
                md={editData?.image?.filepath ? "11" : "12"}
                className="mb-1"
              >
                <Label for="image">Image</Label>
                <Controller
                  id="image"
                  name="image"
                  control={control}
                  rules={{ required: "Image is required" }}
                  render={({ field }) => (
                    <>
                      <Input
                        type="file"
                        placeholder="Image"
                        onChange={(e) => {
                          field.onChange();
                          handleFile(e, "image");
                        }}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </>
                  )}
                />
                {errors.image && (
                  <FormFeedback>{errors?.image?.message}</FormFeedback>
                )}
              </Col>
              {editData?.image?.filepath && (
                <Col md="1" className="mb-1">
                  <a
                    href={editData.image.filepath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={editData.image.filepath}
                      height={50}
                      width={50}
                      style={{ marginTop: "1rem" }}
                      alt=""
                    />
                  </a>
                </Col>
              )}
          </Row>
          <div className="d-flex justify-content">
               <Button type="submit" color="primary">
                  Submit
                </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default TicketUpdate;
