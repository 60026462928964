import { createSlice } from "@reduxjs/toolkit";

const initState = {
  complainList: [],
  complainView: null,
  complainEdit: null,
  complainDropdown: [],
};

export const complainSlice = createSlice({
  name: "complain",
  initialState: initState,
  reducers: {
    getComplain: (state, { payload }) => {
      state.complainList = payload;
    },
    setComplainView: (state, { payload }) => {
      state.complainView = payload;
    },
    setComplainEdit: (state, { payload }) => {
      state.complainEdit = payload;
    },
    setComplainDropDown: (state, { payload }) => {
      state.complainDropdown = payload;
    },
  },
});

export const {
  getComplain,
  setComplainView,
  setComplainEdit,
  setComplainDropDown,
} = complainSlice.actions;
export default complainSlice.reducer;