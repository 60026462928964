import { createSlice } from "@reduxjs/toolkit";

const initState = {
    wardList:[],
    wardView:null,
    wardEdit:null,
    wardAll:[],
    wardByCityId:[],
    wardDropdown: [],
};

export const wardSlice = createSlice({
      name : "ward",
      initialState: initState,
      reducers: {
        getWard: (state,{payload}) => {
            state.wardList = payload;
        },
        setWardView: (state,{payload}) => {
            state.wardView = payload;
        },
        setWardEdit: (state,{payload}) => {
            state.wardEdit = payload;
        },
        setWard: (state,{payload}) => {
            state.wardAll = payload;
        },
        setWardByCityId:(state,{payload})=>{
            state.wardByCityId = payload;
        },
        setWardDropdown:(state,{payload}) => {
            state.wardDropdown = payload;
        },
      }
})

export const {
    getWard,
    setWardView,
    setWardEdit,
    setWard,
    setWardByCityId,
    setWardDropdown
} = wardSlice.actions;

export default wardSlice.reducer;