import { lazy } from "react";
import LoginComponent from "../components/auth/Login";
import TicketUpdate from "../components/Ticket-Update/TicketUpdate";
import TicketStatus from "../components/User-Ticket-Status/TicketStatus";
/**** MuzafarNagar imports ****/
const CityList = lazy(() => import("../components/city/CityList"));
const CityForm = lazy(() => import("../components/city/CityForm"));
const CityView = lazy(() => import("../components/city/CityView"));

const WardForm = lazy(() => import("../components/ward/WardForm"));
const WardList = lazy(() => import("../components/ward/WardList"));

const WmemberForm = lazy(() => import("../components/ward-member/WmemberForm"));
const WmemberList = lazy(() => import("../components/ward-member/WmemberList"));
const WmemberView = lazy(() => import("../components/ward-member/WmemberView"));

const StaffList = lazy(()=> import("../components/staff-mangement/StaffList"));
const StaffForm= lazy(()=> import("../components/staff-mangement/StaffForm"));

const AdminList = lazy(()=> import("../components/admin-managment/AdminList"));
const AdminForm= lazy(()=> import("../components/admin-managment/AdminForm"));

const UserComplain = lazy(() => import("../components/user-complain/UserComplain"));
const UserComplainList = lazy(() => import("../components/user-complain/UserComplainList"));

const Complaints = lazy(() =>import("../components/complaint-management/Complaints"))


export const privateRoutes = [
  

];

export const superAdminRoutes = [
  { path: "/city-list", Component: CityList },
  { path: "/city-form", Component: CityForm },
  
  {path:"/admin-list", Component:AdminList},
  {path:"/admin-form", Component:AdminForm},

]

export const AdminRoutes = [
  {path: "/staff-list", Component: StaffList },
  {path: "/staff-form", Component: StaffForm },

  {path:"/ward-list",   Component:WardList},
  {path:"/ward-form",   Component:WardForm},

  {path:"/wardmember-list", Component:WmemberList},
  {path:"/wardmember-form", Component: WmemberForm},

  {path:"/complaints", Component:Complaints}
]
export const StaffRoutes = [
  {path:"/complain-form", Component:UserComplain},
  {path:"/complain-list", Component:UserComplainList},
]
export const publicRoutes = [
  { path: "/login", Component: LoginComponent },
  {path:"/update-ticket/:id", Component:TicketUpdate},
  {path:"/user-ticket-status/:id",Component:TicketStatus}
]
