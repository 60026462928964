import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const auth = localStorage.getItem('auth')
const user = JSON.parse(auth)

const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    :  process.env.REACT_APP_PROD_URL;

// export const authApi = createApi({
//   tagTypes: ["auth"],
//   reducerPath: "authApi",
//   baseQuery: fetchBaseQuery({
//     baseUrl: `${baseUrl}/`
//   }),
//   endpoints: (builder) => ({
//     loginAuth: builder.mutation({
//       query: (payload) => ({
//         url: "admin/login",
//         method: "POST",
//         body: payload,
//       }),
//       invalidatesTags: ["auth"],
//     }),
//   }),
//   loginAsAdmin: builder.mutation({
//     query: (payload) => ({
//       url: "admin/staff/login/admin",
//       method: "POST",
//       body: payload,
//     }),
//     invalidatesTags: ["auth"],
//   }),
// });
// export const {
//   useLoginAuthMutation,useLoginAsAdminMutation
// } = authApi;   

export const fileApi = createApi({
  tagTypes: ["fileUpload"],
  reducerPath: "fileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fileUpload: builder.mutation({
      query: (payload) => ({
        url: `uploads?type=${payload?.type}`,
        method: "POST", 
        body: payload?.file,
      }),
      invalidatesTags: ["file"],
    }),
  }),
});

export const { useFileUploadMutation } = fileApi;


export const linksApi = createApi({
  tagTypes: ["links"],
  reducerPath: "linksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchPrivacyLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/privacypolicy-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    fetchRefundLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/refundpolicy-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    fetchTopLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/top-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    fetchQuickLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/quick-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    submitPrivacyLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/privacypolicy-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
    submitRefundLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/refundpolicy-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
    submitTopLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/top-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
    submitQuickLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/quick-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
  }),
});
export const {
  useFetchPrivacyLinksQuery,
  useFetchRefundLinksQuery,
  useFetchTopLinksQuery,
  useFetchQuickLinksQuery,
  useSubmitPrivacyLinksMutation,
  useSubmitRefundLinksMutation,
  useSubmitTopLinksMutation,
  useSubmitQuickLinksMutation
} = linksApi;

export const notificationApi = createApi({
  tagTypes: ["notification"],
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchNotification: builder.query({
      query: (payload) => ({
        url: "appsetting/notification",
        method: "GET",
      }),
      providesTags: ["notification"],
    }),
    submitNotification: builder.mutation({
      query: (payload) => ({
        url: "appsetting/notification",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["notification"],
    }),
  }),
});
export const {
  useFetchNotificationQuery,
  useSubmitNotificationMutation
} = notificationApi;


/******* RTQ Query For Muzafarnagar MCL *******/

export const cityApi = createApi({
  tagTypes: ["city"],
  reducerPath: "city",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createCity : builder.mutation({
      query:(payload) => ({
        url:"city",
        method:"POST",
        body:payload,
      }),
      providesTags:["city"],
    }),
    cityList: builder.mutation({
      query: (payload) => ({
        url: "city/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["city"],
    }),
    cityById: builder.query({
      query: (id) => ({
        url: `city/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["city"],
    }),
    cityDropdown: builder.query({
      query: () => ({
        url: "city/dropdown",
        method: "GET",
      }),
      providesTags: ["city"],
    }),
    deleteCity: builder.mutation({
      query: (id) => ({
        url: `city/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["city"],
    }),
  })
})

export const {
  useCreateCityMutation,
  useCityListMutation,
  useCityByIdQuery,
  useCityDropdownQuery,
  useDeleteCityMutation
}  = cityApi;


// RTQ Query for ward 

export const wardApi = createApi({
  tagTypes: ["ward"],
  reducerPath: "ward",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createWard: builder.mutation({
      query:(payload) => ({
        url:"ward",
        method:"POST",
        body:payload,
      }),
      providesTags:["ward"],
    }),
    wardList: builder.mutation({
      query: (payload) => ({
        url: "ward/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["ward"],
    }),
    wardById: builder.query({
      query: (id) => ({
        url: `ward/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["ward"],
    }),
    wardByCityId: builder.mutation({
     query: (id) => ({
        url:`ward/bycityid/${id}`,
        method:"POST"
     }),
      providesTags:["ward"],
    }),
    wardDropdown: builder.query({
      query: () => ({
        url: "ward/dropdown",
        method: "GET",
      }),
      providesTags: ["ward"],
    }),
    deleteWard: builder.mutation({
      query: (id) => ({
        url: `ward/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ward"],
    }),
  })
})

export const {
  useCreateWardMutation,
  useWardListMutation,
  useWardByIdQuery,
  useWardByCityIdMutation,
  useWardDropdownQuery,
  useDeleteWardMutation
}  = wardApi;


// RTQ Query for ward Member


export const wardMemberApi = createApi({
  tagTypes: ["wardMember"],
  reducerPath: "wardMember",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers,{getState}) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createWardMember: builder.mutation({
      query:(payload) => ({
        url:"wardmember",
        method:"POST",
        body:payload,
      }),
      providesTags:["wardMember"],
    }),
    wardMemberList: builder.mutation({
      query: (payload) => ({
        url: "wardmember/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["wardMember"],
    }),
    wardMemberById: builder.query({
      query: (id) => ({
        url: `wardmember/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["wardMember"],
    }),
    wardMemberByCityId: builder.mutation({
     query: ({cityId,wardId}) => ({
        url:`wardmember/bycityid/${cityId}/${wardId}`,
        method:"POST"
     }),
      providesTags:["wardMember"],
    }),
    wardMemberDropdown: builder.query({
      query: () => ({
        url: "wardmember/dropdown",
        method: "GET",
      }),
      providesTags: ["wardMember"],
    }),
    deleteWardMember: builder.mutation({
      query: (id) => ({
        url: `wardmember/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["wardMember"],
    }),
  })
})

export const {
  useCreateWardMemberMutation,
  useWardMemberListMutation,
  useWardMemberByIdQuery,
  useWardMemberByCityIdMutation,
  useWardMemberDropdownQuery,
  useDeleteWardMemberMutation
}  = wardMemberApi;


// RTQ Query for admin-management

// RTQ Query for staff-management.

export const staffApi = createApi({
  tagTypes: ["staff"],
  reducerPath: "staffApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", user?.token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    StaffList: builder.mutation({
      query: (payload) => ({
        url: "staff/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["staff"],
    }),
    staffById: builder.query({
      query: (id) => ({
        url: `staff/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["staff"],
    }),
    createStaff: builder.mutation({
      query: (payload) => ({
        url: "staff/create",
        method: "POST",
        body: payload,
      }),
      providesTags: ["staff"],
    }),
    deleteStaff: builder.mutation({
      query: (id) => ({
        url: `staff/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["staff"],
    }),
  }),
});
export const {
  useStaffByIdQuery,
  useCreateStaffMutation,
  useDeleteStaffMutation,
} = staffApi;


// RTQ Query for autAadmin

export const authApi = createApi({
  tagTypes: ["auth"],
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query:(payload) => ({
        url:"admin",
        method:"POST",
        body:payload,
      }),
      providesTags:["auth"],
    }),
    userById: builder.query({
      query: (id) => ({
        url: `admin/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["auth"],
    }),
    loginAuth: builder.mutation({
      query: (payload) => ({
        url: "admin/login",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["auth"],
    }),
    loginAsAdmin: builder.mutation({
      query: (payload) => ({
        url: "admin/staff/login/admin",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["auth"],
    }),
    userList:builder.mutation({
      query: (payload) => ({
        url:"admin/list",
        method:"POST",
        body:payload,
      }),
      invalidatesTags: ['auth'],
    }),
    staffList:builder.mutation({
      query: (payload) => ({
        url:"admin/staff/list",
        method:"POST",
        body:payload,
      }),
      invalidatesTags: ['auth'],
    }),
  }),
});
export const {
  useCreateUserMutation,
  useUserByIdQuery,
  useLoginAuthMutation,
  useLoginAsAdminMutation,
  useUserListMutation,
  useStaffListMutation,
} = authApi;  


// RTQ for complaints

export const complainApi = createApi({
  tagTypes: ["complain"],
  reducerPath: "complain",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    complainList: builder.mutation({
      query: (payload) => ({
        url: "complain/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["complain"],
    }),
    complainById: builder.query({
      query: (id) => ({
        url: `complain/By/${id}`,
        method: "GET",
      }),
      providesTags: ["complain"],
    }),
    complainTicketById: builder.query({
      query: (id) => ({
        url: `complain/ticket/By/${id}`,
        method: "GET",
      }),
      providesTags: ["complain"],
    }),
    creatComplain: builder.mutation({
      query: (payload) => ({
        url: "complain",
        method: "POST",
        body: payload,
      }),
      providesTags: ["complain"],
    }),
    editComplain: builder.mutation({
      query: (payload) => ({
        url: "complain/update",
        method: "POST",
        body: payload,
      }),
      providesTags: ["complain"],
    }),
    deleteComplain: builder.mutation({
      query: (id) => ({
        url: `complain/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["complain"],
    }),
  }),
});
export const {
  useComplainListMutation,
  useComplainByIdQuery,
  useComplainTicketByIdQuery,
  useCreatComplainMutation,
  useEditComplainMutation,
  useDeleteComplainMutation,
} = complainApi;


export const complainTicketApi = createApi({
  tagTypes: ["complainticket"],
  reducerPath: "complainticket",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    complainTicketList: builder.mutation({
      query: (payload) => ({
        url: "complain/ticket/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["complainticket"],
    }),
    // complainTicketById: builder.query({
    //   query: (id) => ({
    //     url: `complain/ticket/byid/${id}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["complainticket"],
    // }),
  }),
});
export const {
  useComplainTicketListMutation,
  // useComplainTicketByIdQuery,
} = complainTicketApi;

export const UserTicketStatusApi = createApi({
  tagTypes: ["userticket"],
  reducerPath: "userticket",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      if(getState()?.authState?.userToken){
        headers.set('Authorization', getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    UserTicketList: builder.mutation({
      query: (payload) => ({
        url: "User/ticket/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["userticket"],
    }),
    userTicketById: builder.query({
      query: (id) => ({
        url: `user/ticket/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["userticket"],
    }),
  }),
});
export const {
    useUserTicketListMutation,
    useUserTicketByIdQuery
} = UserTicketStatusApi;
