import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import userAvatar from "../assets/images/portrait/small/avatar-s-11.jpg";
import { Link, Menu, Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { setUserInfo, setUserToken } from "../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLoginAsAdminMutation } from "../service";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.authState.userInfo);
  const [width, setWidth] = useState(0)
  const [loginBackAdminReq, loginBackAdminRes] = useLoginAsAdminMutation();
  console.log('width',width);
  
  useEffect(() => {
    function handleResize() {
      if(window.innerWidth < 1200){
        document.querySelector(".main-menu").classList.add("collapse");
        document.querySelector(".main-menu").classList.remove("expanded");
      } else{
        document.querySelector(".main-menu").classList.remove("collapse");
        document.querySelector(".main-menu").classList.add("expanded");
      }
      setWidth(window.innerWidth)
    }
    
    window.addEventListener("resize", handleResize)
    
    handleResize()
    
    return () => { 
      window.removeEventListener("resize", handleResize)
    }
  }, [setWidth])

  const menuCollpse = () => {
    document.querySelector(".main-menu").classList.remove("collapse");
    document.querySelector(".main-menu").classList.add("expanded");
  };

  const handleLogout = () => {
    localStorage.clear();
    cookies.remove("MMCL");
    cookies.remove("MMCL_user");
    cookies.remove("isLoggedIn");
    dispatch(setUserInfo({}));
    dispatch(setUserToken(""));
    navigate('/');
  }

  return (
    <Navbar
      expand="lg"
      container={false}
      className={
        "header-navbar navbar align-items-center floating-nav container-xxl navbar-shadow navbar navbar-expand-lg navbar-light"
      }
    >
      <div className="navbar-container d-flex content">
        <div className="bookmark-wrapper d-flex align-items-center">
          <div className='bookmark-wrapper d-flex align-items-center'>
          {width < 1200 && <Menu 
          onClick={menuCollpse}
          /> }
          </div>
        </div>
        <ul className="nav navbar-nav align-items-center ms-auto">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle
              href="/"
              tag="a"
              className="nav-link dropdown-user-link"
              onClick={(e) => e.preventDefault()}
            >
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name fw-bold text-capitalize">{userInfo ? userInfo?.role: ''}</span>
                {/* <span className="user-status">{user ? user?.role : 'Admin'}</span> */}
              </div>
              <div className="avatar">
                <img
                  className=""
                  src={userAvatar}
                  alt="avatarImg"
                  height="40"
                  width="40"
                />
                <span className="avatar-status-online"></span>
              </div>
            </DropdownToggle>
            <DropdownMenu end>
            <DropdownItem  onClick={() => handleLogout()}>
              <Power size={14} className='me-75' />
              <span className='align-middle'>Logout</span>
            </DropdownItem>
          </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
      </div>
    </Navbar>
  );
}

export default Header;
