import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initState = {
    userInfo: cookies?.get('MMCL_user'), // for user object
    userToken: cookies?.get('MMCL'), // for storing the JWT,
    isLoggedIn: false,
    userEdit:null,
    userList:[],
    timer:0
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    getUser: (state, { payload }) => {
      state.userList = payload;
    },
    setUserToken: (state, { payload }) => {
      state.userToken = payload;
    },
    setUserInfo: (state, { payload }) => {
        state.userInfo = payload;
    },
    getUserToken: (state, { payload }) => {
        state.userToken = payload;
    },
    setUserEdit: (state,{payload}) => {
      state.userEdit = payload;
  },
    getUserInfo: (state, { payload }) => {
        state.userInfo = payload;
    },
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setTimer: (state, { payload }) => {
      state.timer = payload;
    },
  },
});

export const {
  getUser,
  setUserToken,
  setUserInfo,
  setIsFrench,
  getUserToken,
  setUserEdit,
  getUserInfo,
  getIsFrench,
  setIsLoggedIn,
  setTimer
} = authSlice.actions;

export default authSlice.reducer;
