import { createSlice } from "@reduxjs/toolkit";

const initState = {
  ticketList: [],
  complainTicket: null,
  ticketEdit: null,
};

export const complainticketSlice = createSlice({
  name: "contact",
  initialState: initState,
  reducers: {
    getComplainTickets: (state, { payload }) => {
      state.ticketList = payload;
    },
    setComplainTicket: (state, { payload }) => {
      state.complainTicket = payload;
    },
    setTicketEdit: (state, { payload }) => {
      state.ticketEdit = payload;
    },
  },
});

export const { getComplainTickets, setComplainTicket, setTicketEdit } =
  complainticketSlice.actions;
export default complainticketSlice.reducer;
